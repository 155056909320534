@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.numberVerifyModal{
  & .buttonBox{
    width: 200px;
    margin-left: auto;
    padding: 0 30px;
  }
}
.errorText{
  color: red;
}
.phoneNumber{
display: flex;
border: 1px solid #2e2825;
border-radius: 6px;
& input{
  padding: 3px 16px 3px 16px;
  border: 0;
  border-radius: 0;
  &:focus{
    box-shadow: none;
  }
}
}
.codeNumber{
  border-right: 1px solid #2e2825;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px 10px 10px 10px;  
  font-family: var(--fontFamily);
  font-size: 16px;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadow);

  flex-wrap: wrap;
  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    display: none;
  }
}

.mobileLogo {
  height: auto;
  margin-left: 22px;
  padding: 0 !important;
  & > div {
    height: 30px;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: 68px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;
  justify-content: center;
  height: auto;
  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menuRoot {
  height: auto;
  & > div {
    top: 55px;
    & > div {
      left: unset !important;
      right: 20px !important;
    }
  }
}

.searchMenu {
  border-width: 0px;
  background-color: transparent;
  width: 100%;

  display: flex;
  & input {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #374151;
  }
  & button {
    border: 0;
    margin-left: -29px;
    min-width: 35px;
    & svg {
      transform: rotate(256deg);
      @media (max-width: 767px) {
        height: 16px;
        width: 16px;
      }
    }
    & g {
      stroke: #000;
    }
  }
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 2px 5px;
  position: relative;
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  gap: 7px;
  align-items: center;
  margin: 9px 23px 0 0;
  @media (--viewportLarge) {
    padding: 21px 24px;
  }
  &:after{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
  }
}

.avatarSm {
  height: 22px;
  width: 22px;
  position: relative;

}

.searchMenu {
  width: 100%;
}
.searchMenu {
  padding: 0px 24px;
  & > form {
    border-bottom: 2px solid #1f2937;
  }
  @media (--viewportLarge) {
    padding: 18px 24px;
  }
  @media (max-width: 1023px) {
    & input {
      padding: 6px 12px 4px 0px;
    }
  }
}

.searchMenuIcon {
  margin: 9px 0;
}
.menuIcon {
  margin: 2px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: 100vh;
    height: 100%;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.linkedLogo {
  padding-left: 22px;
  & div {
    height: auto;
  }
}

.logoLink {
  height: 100%;
  color: var(--Black, #000);
  font-family: 'bon-vivant';
  font-size: 27.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 130.317% */
  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  &:hover {
    text-decoration: none;
  }
}

.profileMenuContent {
  min-width: 160px !important;
  width: 160px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #f3cac1bf;
  right: 23px !important;
  left: unset !important;
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;
    background-color: rgb(239 228 211 / 1);
    /* & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  margin: 0;
  padding: 6px 16px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: rgb(239 228 211 / 1);
    /* & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.loginModal{
 @media (max-width: 767px) {
  & :global(#modal-container){
    overflow-y: scroll;
  }
 }
  & > div{
    @media (max-width: 767px) {
      overflow: inherit !important;
    }
    & > div{
      max-width: calc(100vw - 20px) !important;
    }
  }
}