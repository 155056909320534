@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

/* logo */
.logoLink {
  order: 2;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--Black, #000);
  font-family: 'bon-vivant';
  font-size: 27.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 130.317% */
  @media (min-width: 1024px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  @media (min-width: 1400px) {
    font-size: 32px;
  }
  &:hover{
    text-decoration: none;
  }
}

/* Search */
.searchLink {
  order: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);

  @media (--viewportLarge) {
    padding-left: 0;
    display: flex;
    align-items: center;
    flex-basis: 437px;
  }
}

.linksWrapper {
  display: flex;
  order: 3;
  flex-basis: 467px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  margin-left: 24px;
  border-bottom: 1px solid #1f2937;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  @media (--viewportLarge) {
    padding-left: 0px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: 2px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 160px !important;
  width: 160px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  border:1px solid #f3cac1bf;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 6px;
  padding-bottom: 6px;
  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;
    background-color: rgb(239 228 211 / 1);
    /* & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  margin: 0;
  padding: 6px 16px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;


  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: rgb(239 228 211 / 1);
    /* & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    } */
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.likeLink {
  position: relative;
  margin-right: 14px;
  cursor: pointer;
  & .count {
    position: absolute;
    right: -6px;
    top: -6px;
    display: flex;
    width: 16px;
    height: 16px;
    padding: 1px 4.349px 2px 4.68px;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background: var(--Burnt-Rose---Secondary, #b06b5d);
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 240% */
  }
}

.bagLink {
  composes: likeLink;
}

.loginLink {
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.topbarSearchWrapper{
  display: flex;
  max-width: 465px;
  width: 100%;
  height: 100%;
  align-items: center;
  & input{
    padding: 3px 16px 3px 16px;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #1F2937;
    &:focus{
      box-shadow: none;
    }
  }
  & button{
    border: 0;
    margin-left: -29px;
    min-width: 35px;
     & svg{
      transform: rotate(274deg);
     }
    & g{
      stroke: #000;
    }
  }
}
.numberVerifyModal{
  & .buttonBox{
    width: 200px;
    margin-left: auto;
    padding: 0 30px;
  }
}
.errorText{
  color: red;
}
.phoneNumber{
display: flex;
border: 1px solid #2e2825;
border-radius: 6px;
& input{
  padding: 3px 16px 3px 16px;
  border: 0;
  border-radius: 0;
  &:focus{
    box-shadow: none;
  }
}
}
.codeNumber{
  border-right: 1px solid #2e2825;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px 10px 10px 10px;  
  font-family: var(--fontFamily);
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}